<template>
  <ContentLayout>
    <h2>404</h2>
    <h1>Страница не найдена</h1>
    <button @click="goHome" class="button button-default button_big">
      Вернуться
    </button>
  </ContentLayout>
</template>

<script>
import ContentLayout from "@/layout/ContentLayout.vue";

export default {
  components: {
    ContentLayout,
  },
  methods: {
    goHome() {
      this.$router.push({ name: "home" });
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  margin-top: 80px !important;
}
</style>
